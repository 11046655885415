import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import VistaGlobal from '../layouts/VistaGlobal/VistaGlobal';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const Escenarios = Loadable(lazy(() => import('../views/escenario/Escenarios')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));
const Evento = Loadable(lazy(() => import('../views/evento/Evento')));
const VistaUnitariaEvento = Loadable(
  lazy(() => import('../views/evento/vistaunitaria/VistaUnitariaEvento')),
);
const VistaParticipantes = Loadable(
  lazy(() =>
    import('../views/evento/vistaunitaria/participantes/vistaParticipantes/IndexParticipantes'),
  ),
);
const VistaEstacionamiento = Loadable(
  lazy(() =>
    import(
      '../views/evento/vistaunitaria/estacionamiento/vistaEstacionamiento/IndexEstacionamiento'
    ),
  ),
);
const VistaActividadEvento = Loadable(
  lazy(() =>
    import(
      '../views/evento/vistaunitaria/registroactividad/VistaActividadEvento/IndexActividadEvento'
    ),
  ),
);

const VistaGlobalEvento = Loadable(lazy(() => import('../views/VistaGlobal/VistaGlobalEvento')));

/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '', exact: true, element: <Dashboard /> },
      { path: '/misescenarios', exact: true, element: <Escenarios /> },
      { path: '/eventos', exact: true, element: <Evento /> },
      { path: '/eventos/:id', exact: true, element: <VistaUnitariaEvento /> },
      { path: '/eventos/:id/participantes', exact: true, element: <VistaParticipantes /> },
      { path: '/eventos/:id/estacionamiento', exact: true, element: <VistaEstacionamiento /> },
      { path: '/eventos/:id/registroactividad', exact: true, element: <VistaActividadEvento /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '404', element: <Error /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: '', element: <Navigate to="/auth/login" /> },
      { path: 'register', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/view/event',
    element: <VistaGlobal />,
    children: [{ path: '', element: <VistaGlobalEvento /> }],
  },
];

export default Router;
