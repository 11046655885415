import { Box, Container, experimentalStyled } from '@mui/material';
import React from 'react';

import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Customizer from '../full-layout/customizer/Customizer';

const MainWrapper = experimentalStyled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  overflow: 'hidden',
  width: '100%',
}));
const PageWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    paddingTop: 30,
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: '30px',
  },
}));

const VistaGlobal = () => {
  const customizer = useSelector((state) => state.CustomizerReducer);

  return (
    <MainWrapper className={customizer.activeMode === 'dark' ? 'darkbg' : ''}>
      <PageWrapper>
        <Container
          maxWidth={false}
          sx={{
            paddingTop: '10px',
          }}
        >
          <Box sx={{ minHeight: 'calc(100vh - 170px)' }}>
            <Outlet />
          </Box>
          <Customizer />
        </Container>
      </PageWrapper>
    </MainWrapper>
  );
};

export default VistaGlobal;
